<script setup>
import { onMounted, ref } from "vue"

defineProps(["modelValue"])

defineEmits(["update:modelValue"])

const input = ref(null)

onMounted(() => {
  if (input.value.hasAttribute("autofocus")) {
    input.value.focus()
  }
})
</script>

<template>
  <input
    class="border-gray-300 text-gray-800 focus:border-cerulean-500 focus:none rounded-md shadow-sm placeholder:text-gray-300"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    ref="input"
  />
</template>
